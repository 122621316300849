<template>
	<div class="filter-layout">
		<el-card class="filter-container" shadow="never">
			<div v-if="arg.dropdown" style="display: flex;flex-wrap: wrap;">
				<!-- <el-dropdown class="umar-b10 float-l" @command="handleCommand">
					<el-button type="primary">数据同步<i class="el-icon-arrow-down el-icon--right"></i> </el-button>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item :command="{ tag: 'base_sync', forced: false }">同步产品基础信息</el-dropdown-item>
						<el-dropdown-item :command="{ tag: 'pic_sync', forced: false }">同步图片</el-dropdown-item>
						<el-dropdown-item :command="{ tag: 'detail_sync', forced: false }">同步详情</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
				<el-dropdown class="umar-b10 float-l umar-l10" @command="handleCommand">
					<el-button type="primary">强制同步<i class="el-icon-arrow-down el-icon--right"></i> </el-button>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item :command="{ tag: 'base_sync', forced: true }">同步产品基础信息</el-dropdown-item>
						<el-dropdown-item :command="{ tag: 'pic_sync', forced: true }">同步图片</el-dropdown-item>
						<el-dropdown-item :command="{ tag: 'param_sync', forced: true }">同步参数</el-dropdown-item>
						<el-dropdown-item :command="{ tag: 'detail_sync', forced: true }">同步详情</el-dropdown-item>
						<el-dropdown-item :command="{ tag: 'stock_sync', forced: true }">同步库存</el-dropdown-item>
						<el-dropdown-item :command="{ tag: 'price_sync', forced: true }">同步价格</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown> -->
				<el-button class="margin-left10" type="primary" @click="putClick">{{$t('common.Batchlisting')}}</el-button>
				<el-button class="margin-left10" type="primary" @click="offClick">{{$t('common.Batchdelisting')}}</el-button>
				<el-button class="margin-left10" type="primary" @click="searchClick">{{$t('common.Batchsearch')}}</el-button>
				<el-button class="margin-left10" type="primary" @click="countryClick">{{$t('common.Editlistingcountry')}}</el-button>
				<el-button class="margin-left10" type="primary" @click="classClick">{{$t('common.EditCategory')}}</el-button>
				<el-button v-if="!scene.isNonSelf" class="margin-left10" type="primary" @click="SKUClick">{{$t('common.SynchronizeSKU')}}</el-button>
                <el-button v-if="!scene.isNonSelf" class="margin-left10" type="primary" @click="subBarCodeClick">{{$t('common.SynchronizeSubBarcodes')}}</el-button>
				<el-button class="margin-left10" type="primary" @click="markClick">{{$t('common.Setlabel')}}</el-button>
				<!-- <el-button v-if="currentTenant!=tenant_idMall" style="margin-left: 10px" type="primary" @click="baseline">底线库存</el-button> -->

				<el-button v-if="!scene.isNonSelf" class="margin-left10" type="primary" @click="EBPClick">{{$t('common.SynchronizeEBP')}}</el-button>
				<el-button v-if="!scene.isNonSelf" class="margin-left10" type="primary" @click="packageQtyClick">{{$t('common.SynchronizeThePackageQuantity')}}</el-button>
				<el-button v-if="!scene.isNonSelf" class="margin-left10" type="primary" @click="outStockClick">{{$t('common.Noinventorydisplay')}}</el-button>
				<input-upload v-if="!scene.isNonSelf" class="margin-left10"></input-upload>

				<!-- <el-button class="umar-b10  upload-btn" type="primary"
				    >导入文件
				    <div id="clone_input">
				        <input class="upload-btn-file" type="file" ref="file" @change="importClick" :accept="accept" />
				    </div>
				</el-button> -->
				<el-button v-if="!scene.isNonSelf" class="margin-left10" type="primary" @click="recordClick">
					{{$t('common.UpdateRecords')}}
				</el-button>
				<!-- <el-link v-if="!scene.isNonSelf"
					class="umar-l10 margin-left10"
					href="https://aigo-oss-mall.oss-eu-central-1.aliyuncs.com/b2b/template/%E5%95%86%E5%93%81%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx"
					target="_blank"
					><el-button v-if="!scene.isNonSelf" type="primary">{{$t('common.DownloadImportTemplate')}}</el-button></el-link
      			>  -->

				<el-button v-if="!scene.isNonSelf" class="margin-left10" type="primary" @click="downloadTemplateGoods" >{{$t('common.DownloadImportTemplate')}}</el-button>

				<el-button v-if="scene.isNonSelf" :loading="loading" class="margin-left10" type="primary" @click="downloadFileByDkAndNo">
					{{$t('common.DownloadTheLatestProductInformationSheet')}}
				</el-button>
				<!-- <span class="down-template" @click="downloadTemplateClick"><span>下载导入模板</span></span> -->
			</div>

			<div class="margin-left10">
				<el-form :inline="true" :model="listQueryData" size="small" label-width="140px">
					<el-form-item v-if="arg.name">
						<el-input v-model="listQueryData.title" @input="e => listQueryData.title = validForbid(e)" class="input-width" clearable :placeholder="placeholder.name  || 'ID'"></el-input>
					</el-form-item>
					<el-form-item v-if="arg.name2">
						<el-input v-model="listQueryData.title2" class="input-width" :placeholder="placeholder.name2  || $t('common.Redemptioncode')" clearable></el-input>
					</el-form-item>
					<el-form-item v-if="arg.name3">
						<el-input v-model="listQueryData.title3" class="input-width" :placeholder="placeholder.name3  || $t('common.Useordernumber')" clearable></el-input>
					</el-form-item>
					<el-form-item v-if="arg.parameter">
						<el-select v-model="listQueryData.parameter"  class="input-width" :placeholder="$t('common.type')">
							<el-option v-for="item in dictionary.type" :key="item.id" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="arg.parameterName">
						<el-input v-model="listQueryData.parameterName" class="input-width" :placeholder="$t('common.ParameterName')"></el-input>
					</el-form-item>							
					<el-form-item v-if="arg.type">
						<el-select v-model="listQueryData.type"  class="input-width" :placeholder="$t('common.type')" clearable>
							<el-option v-for="item in dictionary.type" :key="item.id" :label="item.name"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="arg.code">
						<el-input v-model="listQueryData.code" class="input-width" :placeholder="$t('common.SKU_EAN_Encoding')" clearable></el-input>
					</el-form-item>
					<el-form-item v-if="arg.put">
						<el-select v-model="listQueryData.put"  class="input-width" :placeholder="$t('common.OnTheShelf')">
							<el-option v-for="item in statusOptions" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="arg.category">
						<el-cascader :placeholder="$t('common.Pleaseselectacategory')" ref="myCategory" v-model="listQueryData.classifyTree" :options="dictionary.classifyTree"
						  :props="{checkStrictly: true,value:'goodsClassifyId',label:'title',children:'children'}" @change="handleChangeCategory" :show-all-levels="false" clearable></el-cascader>
					</el-form-item>
					<el-form-item v-if="arg.country">
						<div v-if="multiple">
							<el-select v-model="listQueryData.country"  multiple class="input-width" :placeholder="placeholder.country || $t('common.SalesCountry')" clearable>
								<el-option
									v-for="item in dictionary.country"
									:key="item.id"
									:label="item.name"
									:value="item.code">
								</el-option>      
							</el-select>
						</div>
						<div v-else>
							<el-select v-model="listQueryData.country"  class="input-width" :placeholder="placeholder.country || $t('common.SalesCountry')" clearable>
								<el-option
									v-for="item in dictionary.country"
									:key="item.id"
									:label="item.name"
									:value="item.code">
								</el-option>      
							</el-select>
						</div>
					</el-form-item>
					<el-form-item v-if="arg.position">
						<el-select v-model="listQueryData.position"  class="input-width" :placeholder="$t('common.position')" clearable>
							<el-option v-for="(item,index) in dictionary.bannerPosition" :key="index" :label="item.name"
								:value="item.code">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="arg.brand">
						<el-select v-model="listQueryData.brand"  class="input-width" :placeholder="$t('common.brand')" clearable>
							<el-option v-for="(item,index) in dictionary.brandCode" :key="index" :label="item.name"
								:value="item.code">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="arg.appStatus">
						<el-select v-model="listQueryData.appStatus"  class="input-width" :placeholder="$t('common.Isthehomepagedisplayed')" clearable>
							<el-option v-for="item in dictionary.appStatus" :key="item.id" :label="item.name"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="arg.jumpType">
						<el-select v-model="listQueryData.jumpType" class="input-width" :placeholder="placeholder.jumpType || $t('common.JumpType')" clearable>
							<el-option v-for="item in dictionary.jump" :key="item.id" :label="item.name"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="arg.fixedCode">
						<el-select v-model="listQueryData.fixedCode"  class="input-width" :placeholder="$t('common.Isthereafixedcode')" clearable>
							<el-option v-for="item in dictionary.fixedCode" :key="item.id" :label="item.name"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="arg.czr">
						<el-select v-model="listQueryData.czr"  class="input-width" :placeholder="$t('common.Operator')">
							<el-option v-for="item in dictionary.czr" :key="item.id" :label="item.name"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="arg.yuqi">
						<el-select v-model="listQueryData.yuqi"  class="input-width" :placeholder="$t('common.state')" clearable>
							<el-option v-for="item in dictionary.yuqi" :key="item.id" :label="item.name"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="arg.fixedPrice">
						<el-select v-model="listQueryData.fixedPrice"  class="input-width" :placeholder="$t('common.Isthereafixedprice')" clearable>
							<el-option v-for="item in dictionary.fixedPrice" :key="item.id" :label="item.name"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="arg.labelMark">
						<el-select v-model="listQueryData.mark"  class="input-width" :placeholder="$t('common.label')" clearable>
							<el-option v-for="item in dictionary.markList" :key="item.code" :label="item.name"
								:value="item.code">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="arg.operator && !scene.isNonSelf">
						<el-select v-model="listQueryData.operator"  class="input-width" :placeholder="$t('common.Operator')" clearable>
							<el-option v-for="item in dictionary.operator" :key="item.user_id" :label="item.legal_name"
								:value="item.user_id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="arg.promotion">
						<el-select v-model="listQueryData.promotion"  class="input-width" :placeholder="placeholder.promotion || $t('common.Whethertopromoteornot')" clearable>
							<el-option v-for="item in dictionary.promotion" :key="item.id" :label="item.name"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>


					<!-- <el-form-item v-if="arg.preSale">
						<el-select v-model="listQueryData.preSale"  class="input-width" :placeholder="placeholder.preSale || $t('common.Presaleornot')" clearable>
							<el-option v-for="item in dictionary.preSale" :key="item.id" :label="item.name"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item> -->
					<el-form-item v-if="arg.preSaleCountryCode">
						<el-select v-model="listQueryData.preSaleCountryCode"  class="input-width" :placeholder="placeholder.preSaleCountryCode || $t('common.PreSaleEvent')" clearable>
							<el-option v-for="item in dictionary.country" :key="item.id" :label="item.name"
								:value="item.code">
							</el-option>
						</el-select>
					</el-form-item>

					<el-form-item v-if="arg.receiveState">
						<el-select v-model="listQueryData.isReceived"  class="input-width" :placeholder="placeholder.receiveState || $t('common.Claimstatus')" clearable>
							<el-option v-for="item in dictionary.isReceived" :key="item.value" :label="item.name"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					
					<el-form-item v-if="arg.state">
						<el-select v-model="listQueryData.state"  class="input-width" :placeholder="placeholder.state || $t('common.Activitystatus')" clearable>
							<el-option v-for="item in dictionary.state" :key="item.id" :label="item.name"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="arg.enableStatus">
						<el-select v-model="listQueryData.enableStatus"  class="input-width" :placeholder="placeholder.enableStatus || $t('common.EnabledStatus')" clearable>
							<el-option v-for="item in dictionary.enableStatus" :key="item.id" :label="item.name"
												 :value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="arg.over">
						<el-select v-model="listQueryData.isOverlayDiscount"  class="input-width" :placeholder="$t('common.Overlayornot')" clearable>
							<el-option v-for="item in dictionary.over" :key="item.id" :label="item.name"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="arg.enable">
						<el-select v-model="listQueryData.enable"  class="input-width" :placeholder="$t('common.Enableornot')" clearable>
							<el-option v-for="item in dictionary.status" :key="item.id" :label="item.name"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="arg.seckill">
						<el-select v-model="listQueryData.seckill"  class="input-width" :placeholder="placeholder.seckill || $t('common.Isitaflashkill')" clearable>
							<el-option v-for="item in dictionary.seckill" :key="item.id" :label="item.name"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="arg.date">
						<el-date-picker v-model="listQueryData.date" type="daterange" :range-separator="$t('common.to')"
							:start-placeholder="placeholder.date.start || $t('common.Startdate')" :end-placeholder="placeholder.date.end || $t('common.Enddate')"
							format="yyyy-MM-dd" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']"/>
					</el-form-item>
					<el-form-item v-if="arg.date2">
						<el-date-picker v-model="listQueryData.date2" type="daterange" :range-separator="$t('common.to')"
							:start-placeholder="placeholder.date2.start || $t('common.Startdate')" :end-placeholder="placeholder.date2.end || $t('common.Enddate')"
							format="yyyy-MM-dd" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']"/>
					</el-form-item>
					<el-form-item v-if="arg.useState">
						<el-select v-model="listQueryData.isUsed"  class="input-width" :placeholder="placeholder.receiveState || $t('common.Usagestatus')" clearable>
							<el-option v-for="item in dictionary.isUsed" :key="item.value" :label="item.name"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>

					<el-form-item v-if="arg.userType">
						<el-select v-model="listQueryData.userType"  class="input-width" :placeholder="placeholder.userType || $t('common.ApplicableUserType')" clearable>
							<el-option v-for="item in dictionary.userType" :key="item.id" :label="item.name"
								:value="item.code">
							</el-option>
						</el-select>
					</el-form-item>
					
					<el-form-item v-if="arg.stockCountry">
						<el-select v-model="listQueryData.stockCountryCode"  style="width:100px;" :placeholder="placeholder.stockCountry || $t('common.Country')" clearable>
								<el-option
									v-for="item in dictionary.country"
									:key="item.id"
									:label="item.name"
									:value="item.code">
								</el-option>      
						</el-select>
						<el-select v-model="listQueryData.isStock"  style="width:150px;" :placeholder="placeholder.inventory || $t('common.inventory')" clearable>
								<el-option
									v-for="item in dictionary.stockStatus"
									:key="item.id"
									:label="item.name"
									:value="item.value">
								</el-option>      
						</el-select>
					</el-form-item>


					<el-form-item>
						<el-button v-if='isShowObject.export' style="float:right"  type="primary" @click="handleExport()" size="small" :loading="fullscreenLoading"
						    >{{placeholder.exportText}}</el-button>
						<el-button style="float:right ;margin-right: 15px" @click="handleResetSearch()" size="small">
							{{ $t('common.Reset') }}
						</el-button>
						<el-button style="float:right" type="primary" @click="handleSearchList()"
							size="small">

							{{ $t('common.query') }}

						</el-button>					
					</el-form-item>
					<el-form-item v-if="arg.showColumn">
						<el-button style="float:right ;margin-left: 1200px" @click="handleShowColumn()" size="small">
							{{ $t('common.ListDisplay') }}
						</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-card>
	</div>
</template>

<script>
	import Config from '@/utils/config';
	import InputUpload from '@/components/common/InputUpload.vue';
	import i18n from '@/i18n/i18n.js';
	import scene from "@/utils/scene";
    import Scene from '@/utils/scene';
	export default {
		components: {
			InputUpload,
		},
		props: {
			defaultListQuery:{
				type: Object,
				default: () => {
					return {}
				}
			},
			dictionary: {
				//字典
				type: Object,
				default: () => {
					return {}
				}
			},
			listQuery: {
				//数据
				type: Object,
				default: () => {
					return {}
				}
			},
			isShowObject:{
				type: Object,
				default: () => {
					return {
						export:false
					}
				}
			},
			accept: {
			    type: Array,
			    default: () => {
			    	return ['.pdf','.doc','.docx','.xls','.xlsx']
			    }		
			},
			importAction: {
			    type: String,
			    default: ''
			},
			multiple:{
				type:Boolean,
				default:true
			},
			loading:{
				type:Boolean,
				default:false
			},
			arg: {
				//标签是否显示
				type: Object,
				default: () => {
					return {
						name: false,
						name2: false,
						name3: false,
						brand: false,
						czr: false,
						date: false,
						date2: false,
						code: false,
						type:false,
						country: false,
						status: false,
						dropdown: false,
						category:false,
						promotion:false,
						jumpType:false,
						position:false,
						enable:false,
						put:false,
						state:false,
						over:false,
						parameterName:false,
						parameter:false,
						useState:false,
						appStatus:false,
						showColumn:false
					}
				}
			},
			placeholder: {
				//placeholdert替换
				type: Object,
				default: () => {
					return {
						name:"ID",
						name2:i18n.t('common.Redemptioncode'),
						name3:i18n.t('common.Useordernumber'),
						state:i18n.t('common.Activitystatus'),
						date: {
							start: i18n.t('common.Startdate'),
							end: i18n.t('common.Enddate')
						},
						date2: {
							start: i18n.t('common.Startdate'),
							end: i18n.t('common.Enddate')
						},
						exportText:i18n.t('common.Import')
					}
				}
			}
		},
		data() {
			return {
				currentTenant:Scene.getTenantId(),
				tenant_idMall:Config.tenant_idMall,//商城租户id
				levelList: null,
				listQueryData:this.listQuery,
				fullscreenLoading: false,
			}
		},
		watch: {},
		computed: {
			scene() {
				return scene
			}
		},
		methods: {
			handleCommand(val){
				this.$emit('handleCommand',val)
			},
			handleSearchList(){
				this.$emit('handleSearchList',this.listQueryData)
			},
			handleResetSearch(){
				this.listQueryData = Object.assign({}, this.defaultListQuery);
				this.$emit('handleResetSearch')			
			},
			handleShowColumn(){
				this.$emit('handleShowColumn')
			},
			handleListQueryData(listQuery){
				// console.log('handleListQueryData',listQuery);
				this.listQueryData = Object.assign({}, listQuery);
			},
			putClick(){
				this.$emit('putClick')
			},
			offClick(){
				this.$emit('offClick')				
			},
			countryClick(){
				this.$emit('countryClick')
			},
			classClick(){
				this.$emit('classClick')
			},
			SKUClick(){
				this.$emit('SKUClick')
			},
            subBarCodeClick(){
                this.$emit('subBarCodeClick')
            },
			EBPClick(){
				this.$emit('EBPClick')
			},
			packageQtyClick(){
				this.$emit('packageQtyClick')
			},
			outStockClick(){
				this.$emit('outStockClick')
			},
			downloadTemplateGoods(){
				this.$emit('downloadTemplateGoods')
			},		
			markClick(){
				this.$emit('markClick')
			},
			baseline(){
				this.$emit('baseline')
			},
			recordClick(){
				this.$emit('recordClick')
			},					
			//选择类目
			handleChangeCategory(val){
				this.$emit('handleChangeCategory',val)
			},
			//导入
			handleExport(){
				this.$emit('handleExport',this.listQueryData)
			},
			downloadTemplateClick(){
				this.$emit('downloadTemplateClick')
			},
			downloadFileByDkAndNo(){
				this.$emit('downloadFileByDkAndNo')
			},
			importClick(){
				    let file = this.$refs.file.files[0];
				    let ext = this.getSuffix(file.name);
				    if (this.accept.indexOf(ext) < 0) {
				        this.$message.error(this.$i18n.t('common.OnlySupportsFilesInFormat',{xxx:this.accept}));
				        return;
				    }
				    let formData = new FormData(); //new一个formData事件
				    formData.append('file', this.$refs.file.files[0]); //将file属性添加到formData里
				    this.$store.dispatch('postGoodsImport', formData).then((res) => {
				        if (res && res.status == 200) {
				            if (res.data.success) {
				                this.messageOption = this.$message.success(this.$i18n.t('common.ImportSuccessful'));
				                this.$emit('importSuccess', res.data, this.messageOption);
				            } else this.$message.error(res.data.message);
				        }
				    });
				    this.$refs.file.value = '';
			},
			searchClick(){
				this.$emit('searchClick')
			}
		},
		mounted() {
		},
	}
</script>

<style scoped>
		.filter-layout {}

		.input-width {
			width: 203px;
		}				
		.down-template {
			text-decoration: underline;
			color: rgb(38, 166, 222);
			font-weight: 400;
			font-style: normal;
			font-size: 13px;
			line-height: 13px;
			margin: 20px 0px 0px 10px;
			cursor: pointer;
		}
		.margin-left10{
			margin: 10px 0px 0px 10px
		}

		.filter-container /deep/ .el-card__body{
			padding: 10px;
		}
		
</style>
